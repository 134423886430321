import React from "react";
import loader_icon from "../../assets/loader.svg";

function Loader(){
    return(
        <div className="loader_ico">
            <img src={loader_icon} alt={"loader"} />
        </ div>
    )
}
export default Loader;